import React from "react"

export const transformText = (paragraph, className = '') => {
  const originalText = paragraph?.text;
  const ranges = paragraph?.spans;

  let transformedText = [];
  let currentIndex = 0;

  ranges?.forEach((range, rangeIndex) => {
    transformedText?.push(
      <React.Fragment key={`originalText${rangeIndex}`}>
        {originalText.substring(currentIndex, range?.start)}
        <span className={className}>{originalText?.substring(range?.start, range?.end)}</span>
      </React.Fragment>
    )
    currentIndex = range.end;
  });

  const lastPart = originalText?.substring(currentIndex);
  transformedText.push(
    <React.Fragment key={lastPart || originalText}>
      {lastPart}
    </React.Fragment>
  )

  return transformedText;
}

export const getDataFromLink = (link) => {
  const label = link?.text;
  const attributes = link?.spans[0]?.data;

  return {
    label,
    attributes: {
      href: attributes?.url,
      target: attributes?.target, 
    }
  };
}